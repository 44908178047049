.product-counter {
  display: flex;
  align-items: center;

  &__value {
    text-align: center;
    padding: 8px;
    background-color: var(--primary-white);
    max-width: 60px;
    min-width: 60px;
    height: 40px;
    border: none !important;
    color: var(--primary-black) !important;
  }
}
