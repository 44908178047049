.page-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);

  &__link {
    display: block;
    text-decoration: none;
    padding: 10px;
    border: 1px solid var(--secondary-grey-text);
    border-radius: var(--border-radius-5);
    color: var(--primary-black);
  }
}