.catalog-toggler {
  display: flex;
  align-items: center;
  gap: 16px;

  &__title {
    cursor: pointer;
    transition: color 0.3s ease;

    &.active {
      color: var(--primary-black);
    }
  }

  &__wrapper {
    position: relative;
    width: 40px;
    height: 20px;
    background: var(--primary-white);
    border: 1px solid var(--secondary-grey);
    border-radius: 32px;
    cursor: pointer;
  }

  &__toggle {
    position: absolute;
    top: 50%;
    left: 4px;
    transform: translate(0, -50%);
    width: 12px;
    height: 12px;
    background: var(--primary-orange);
    border-radius: 50%;
    transition: transform 0.3s ease;

    &.right {
      transform: translate(18px, -50%);
    }
  }
}
