.header {
  background: var(--secondary-bg);
  margin-bottom: 60px;
  position: relative;
  z-index: 12;

  @include lg {
    margin-bottom: 40px;
  }

  &:has(.page-header) {
    background-image: url("../assets/images/header-decor-circles.png");
    background-repeat: no-repeat;
    background-position: 100% 100%;

    @include lg {
      background-position: 740px 100%;
    }

    @include md {
      background-position: 570px 100%;
    }

    @include sm {
      background-image: none;
    }
  }

  &:has(.not-found) {
    background-image: url("../assets/images/not-found-decore.png");
    background-repeat: no-repeat;
    background-position: 100% 200px;

    @include lg {
      background-position: 450px 240px;
    }

    @include md {
      background-position: 240% 190px;
    }

    @include sm {
      background-size: 426px;
      background-position: 250px 110px;
    }

    @include xs {
      background-position: 140px 140px;
    }

    @include xxs {
      background-position: 20px 190px;
    }
  }

  &:has(.not-found .not-found__right) {
    background-image: url("../assets/images/not-found-decore.png");
    background-repeat: no-repeat;
    background-position: -350px 250px;

    @include lg {
      background-position: 450px 240px;
    }

    @include md {
      background-position: 270px 120px;
    }

    @include sm {
      background-size: 426px;
      background-position: 270px 140px;
    }

    @include xs {
      background-position: 140px 140px;
    }

    @include xxs {
      background-position: 20px 190px;
    }
  }

  .city-choose {
    @include md {
      display: none;
    }
  }

  &__grid {
    padding: 24px 0;
    display: grid;
    grid-template-columns: 306px 1fr;
    gap: 0 24px;
    grid-template-areas:
      "left top"
      "left bottom";
    border-bottom: 1px solid var(--secondary-grey);

    @include md {
      padding: 16px 0;
      grid-template-areas: "left top bottom";
      grid-template-columns: 216px 1fr auto;
      align-items: center;

      &.active {
        grid-template-areas: "left bottom";
        grid-template-columns: 216px 1fr;
        gap: 0;
      }
    }

    @include sm {
      gap: 12px;
    }

    @media (max-width: 520px) {
      grid-template-areas: "left bottom";
      grid-template-columns: auto auto;
    }
  }

  &__list {
    &.hidden {
      display: none;
    }

    &_bottom {
      .menu-list__link {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: var(--primary-black);
        text-transform: none;
      }
    }

    &_category {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: var(--primary-black);
      display: flex;
      gap: 8px;
      align-items: center;
      padding-bottom: 14px;
      transition: all .2s ease-in-out;

      &:hover {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }
  }

  &__btn-menu {
    display: flex;
    gap: 8px;
    align-items: center;
    padding-bottom: 14px;
    transition: all .2s ease-in-out;


    p {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: var(--primary-black);
    }

    &:hover {
      p {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }
  }

  &__block {
    &_search {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-right: 16px;

      @include md {
        width: 100%;
        margin-right: 12px;
      }

      @include xs {
        width: fit-content;
      }
    }

    &_menu {
      gap: 24px;
      display: flex;
      align-items: center;
      margin-bottom: -14px;

      @include md {
        display: none;
      }
    }

    &.left {
      grid-area: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 12px;
      border-right: 1px solid var(--secondary-grey);

      .mini-logo {
        display: none;
      }

      @include md {
        padding-bottom: 0;
        padding-right: 24px;

        svg {
          width: 193px;
        }
      }

      @media (max-width: 520px) {
        padding-right: 0;
        border: none;
        justify-content: flex-start;
        width: 40px;

        .mini-logo {
          display: block;
          width: 40px;
        }

        .logo {
          display: none;
        }
      }
    }

    &.top {
      grid-area: top;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 14px;
      height: fit-content;

      @include md {
        margin-bottom: 0;
        justify-content: flex-end;
      }

      @include sm {
        display: none;
      }
    }

    &.bottom {
      display: flex;
      align-items: center;
      padding-top: 12px;
      grid-area: bottom;
      border-top: 1px solid var(--secondary-grey);

      @include md {
        padding-top: 0;
        border: none;
      }

      @media (max-width: 520px) {
        justify-content: flex-end;
      }
    }
  }
}