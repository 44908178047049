.short-help {
  display: flex;
  flex-direction: column;
  gap: 40px;

  @include lg {
    flex-direction: row;
    justify-content: space-between;
    gap: var(--gap);
  }

  @include sm {
    flex-direction: column;
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include md {
      margin-right: auto;
      width: 100%;
    }

    @include sm {
      min-width: unset;
    }
  }
}
