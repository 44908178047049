.product-card {
  justify-content: space-between;
  position: relative;

  &:hover {
    .product-card__title {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  &,
  &__info {
    display: flex;
    align-items: center;
    gap: var(--gap);
  }

  @include sm {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    .product-actions {
      padding-top: 12px;
    }
  }

  background-color: var(--secondary-bg);
  border-radius: var(--border-radius-5);
  padding: 16px;

  &__info {
    @include md {
      gap: 16px;
    }

    @include sm {
      padding-bottom: 12px;
      border-bottom: 1px solid var(--secondary-light-grey);
    }
  }

  &__title {
    font-size: 16px;
    transition: all .2s ease-in-out;
  }

  &__photo {
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: var(--border-radius-5);
    overflow: hidden;

    @include md {
      width: 48px;
      min-width: 48px;
      height: 48px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}