.content {
  display: flex;
  flex-direction: column;
  gap: 140px;
  margin-bottom: 140px;

  @include lg {
    gap: 80px;
    margin-bottom: 80px;
  }

  @include sm {
    gap: 60px;
    margin-bottom: 60px;
  }
}
