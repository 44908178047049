.contact-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__item {
    color: var(--primary-black);
    text-decoration: none;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  &__callback {
    display: flex;
    align-items: center;
    gap: 12px;

    @include xs {
      display: none;
    }

    svg {
      stroke: var(--primary-white);
    }
  }
}