.menu-mobile {

  &,
  &__children {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .open {
    .menu-mobile__children {
      &.accordion-content {
        margin-top: 24px;
      }
    }
  }

  &__item {
    height: fit-content;
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      stroke: var(--primary-black);
    }
  }

  &__children {
    .menu-mobile__link {
      font-weight: 700;
      font-size: 13px;
      line-height: 120%;
      text-transform: uppercase;
      color: var(--secondary-grey-text);
    }
  }
}