.work {
  &__slider {
    padding-right: 24px;
    border-right: 1px solid var(--secondary-light-grey);
    width: 100%;
    position: relative;
    padding-left: 24px;
    border-radius: 5px;
    overflow: hidden;

    &_text {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        &:first-child {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 13px;
          line-height: 150%;
          // text-transform: uppercase;
          color: var(--secondary-grey-text);
        }

        &:last-child {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 20px;
          line-height: 150%;
          color: var(--primary-black);
        }
      }
    }



    &:first-child {
      padding-left: 0;

      @media (max-width: 600px) {
        border: none;
        padding-right: 0;
      }
    }

    &:last-child {
      border-right: none;
      padding-right: 0;

      @include lg {
        display: none;
      }
    }

    @include lg {
      &:nth-child(2) {
        border-right: none;
        padding-right: 0;
      }
    }

    @media (max-width: 600px) {
      &:nth-child(2) {
        display: none;
      }
    }

    @media (hover:hover) {
      &:hover {
        .work__control {
          opacity: 1;
          transition: all .2s ease-in-out;
        }
      }
    }
  }

  &__wrapper {
    max-height: 250px;
    height: 100%;
    margin-bottom: 24px;
  }

  &__slide {
    @include lg {
      height: 260px;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__control {
    opacity: 0;
    transition: all .2s ease-in-out;
    position: absolute;
    top: 35%;
    left: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 12px;

    @include sm {
      display: none;
    }
  }

  &__arrow {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 99;
    background: var(--secondary-bg);

    &.swiper-button-disabled {
      opacity: 0.7;
    }
  }
}