.scroll {
  position: absolute;
  bottom: 24px;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  gap: 22px;
  border-radius: 5px;
  padding: 12px;
  width: 110px;
  height: 110px;
  background: var(--secondary-light-grey);
  cursor: pointer;

  @include sm {
    position: unset;
    margin-left: auto;
  }

  svg {
    min-width: 24px;
    min-height: 24px;
  }
}