.delivery-card {
  &__info {
    display: flex;
    gap: var(--gap);
    margin-bottom: var(--gap);
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__how,
  &__price,
  &__desc {
    color: var(--secondary-grey-text);
  }

  &__icon {
    margin-bottom: 32px;

    @include xs {
      margin-bottom: var(--gap);
    }
  }

  &__title {
    margin-bottom: 12px;
  }
}
