.contact {
  line-height: 150%;

  &__title {
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    color: var(--primary-black);
    text-decoration: none;
  }

  &__subtitle {
    font-weight: 400;
    font-size: 14px;
    color: var(--secondary-grey-text);
    text-transform: none;
  }
}
