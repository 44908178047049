.contact-us {
  &__container {
    max-width: 700px;

    @include sm {
      margin: 0 14px;
    }
  }

  &__wrapper {
    background-color: var(--secondary-bg);
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__textarea {
    height: 260px;
  }

  &__input,
  &__textarea {
    background-color: var(--primary-white);
  }
}
