:root {
  // Шрифты
  --font-family: "Arimo", sans-serif;

  // Цвет
  --primary-white: #fff;
  --primary-black: #272d34;
  --primary-blue: #014ca7;
  --primary-orange: #ff5c1b;
  --secondary-bg: #f6f6f7;
  --secondary-light-grey: #edeef0;
  --secondary-grey: #e1e3e7;
  --secondary-grey-text: #7e8185;
  --secondary-success: #7db65a;
  --secondary-error: #f33535;
  --hover-grey: #d1d3d9;
  --hover-blue-hover: #00469a;
  --hover-orange-hover: #e95215;

  // Градиент
  --gradient: linear-gradient(
    138deg,
    rgba(60, 66, 72, 0.5) 0%,
    rgba(60, 66, 72, 0) 100%
  );

  // Остальное
  --border-radius-5: 5px;
  --border-radius-7: 7px;
  --btn-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  --gap: 24px;
}
