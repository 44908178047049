.vacancies__question {
  border-radius: 5px;
  padding: 32px 40px;
  background: var(--primary-white);
  height: 100%;

  @include xs {
    padding: 24px;
  }

  @include md {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  @include sm {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 24px;
  }

  p {
    margin: 0;
  }

  &_icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-orange);
    border-radius: 5px;
    width: 48px;
    height: 48px;
    margin-bottom: 40px;

    @include md {
      margin-bottom: 0;
    }

    @include sm {
      margin-bottom: 24px;
    }
  }

  &_text {
    display: flex;
    flex-direction: column;
    gap: 8px;

    @include sm {
      p {
        &:first-child {
          font-size: 16px;
          line-height: 150%;
        }

        &:last-child {
          font-size: 14px;
          line-height: 150%;
        }
      }
    }
  }

  .short-manager {
    margin-bottom: 70px;
  }

  &_contacts {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &_phone {
    display: flex;
    align-items: center;
    gap: 8px;

    a {
      transition: all .2s ease-in-out;

      &:hover {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }
  }

  &_block {
    @include md {
      width: 50%;
    }

    @include sm {
      width: 100%;
    }

    &:nth-child(1) {
      margin-bottom: 40px;

      @include md {
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }
}