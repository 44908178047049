.about-vacancy {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .body-3 {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--secondary-grey-text);
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 12px;

    ul {
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 12px;

      li {
        display: flex;
        gap: 16px;
        align-items: center;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--secondary-grey-text);

        svg {
          min-width: 8px;
        }
      }
    }
  }
}