.category-block {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: var(--gap);
  }

  &__left {
    width: 100%;
    max-width: 526px;

    @include md {
      max-width: 100%;
    }
  }

  &__right {
    width: 100%;
    max-width: 549px;
    height: 549px;

    @include lg {
      max-width: 272px;
      height: 272px;
    }

    @include md {
      display: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
