.delivery-block {
  &__wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: var(--gap);
    border-bottom: 1px solid var(--secondary-light-grey);

    @include md {
      flex-direction: column;
    }
  }

  &__item {
    flex: 1;
    padding: 0 var(--gap);
    border-right: 1px solid var(--secondary-grey);

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    @include md {
      padding: var(--gap) 0;
      border-right: none;
      border-bottom: 1px solid var(--secondary-grey);

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }

  &__more {
    width: max-content;
  }
}
