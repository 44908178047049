.filter-tags {
  display: flex;
  box-sizing: unset;
  gap: 10px;
  height: 36px;
  position: relative;

  @include lg {
    padding-bottom: var(--gap);
  }

  @media (max-width: 767px) {
    display: none;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    height: 34px;
    overflow: hidden;
    margin-right: 100px;
  }

  &__item {
    padding: 7px 12px;
    background-color: var(--secondary-bg);
    margin-right: 10px;
    margin-bottom: 10px;
    align-items: center;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid var(--secondary-bg);
    text-decoration: none;
    width: max-content;
    height: 34px;

    &:hover {
      background-color: var(--secondary-light-grey);
    }

    &.active {
      background-color: var(--primary-orange);
      color: var(--primary-white);
      border: 1px solid var(--primary-orange);
    }

    &-more {
      display: flex;
      align-items: center;
      gap: 4px;
      background-color: var(--primary-white);
      outline: 1px solid var(--secondary-grey-text);
      outline-offset: -1px;
      position: absolute;
      top: 0;

      svg {
        fill: var(--secondary-grey-text);
        width: 16px;
        height: 16px;
      }
    }

    &-hide {
      display: none;
      align-items: center;
      gap: 4px;
      background-color: var(--primary-white);
      outline: 1px solid var(--secondary-grey-text);
      outline-offset: -1px;

      svg {
        fill: var(--secondary-grey-text);
        width: 16px;
        height: 16px;
      }
    }
  }

  &--open {
    height: unset;

    .filter-tags__wrapper {
      height: unset;
      overflow: unset;
      margin-right: 0;
    }

    .filter-tags__item-more {
      display: none;
      position: unset;
      left: unset !important;
      top: unset;
    }

    .filter-tags__item-hide {
      display: flex;
    }
  }
}
