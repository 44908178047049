.section-swiper {
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  width: 100%;
  padding-bottom: var(--gap);
  border-bottom: 1px solid var(--secondary-light-grey);

  &__header {
    display: flex;
    justify-content: space-between;
    gap: var(--gap);
  }

  &__title {
    padding-bottom: 23px;
    border-bottom: 1px solid var(--secondary-light-grey);
    flex: 1;
  }

  &__nav {
    display: flex;
    gap: 12px;

    & .swiper-button-disabled .btn {
      opacity: 0.4;
    }

    * {
      position: relative;
    }

    &-prev {
      transform: rotate(180deg);
    }

    &-pagination {
      display: none;
    }

    @include xs {
      align-items: flex-end;

      &-prev,
      &-next {
        display: none;
      }

      &-pagination {
        display: block;

        & .swiper-pagination-bullet {
          border-radius: 2px;
          width: 10px;
          height: 10px;
          background-color: var(--primary-orange);

          &:nth-child(n + 4) {
            display: none;
          }
        }
      }
    }
  }

  &__wrapper {
    width: 100%;
  }
}
