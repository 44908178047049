.text-block {
  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--primary-black);

    &:not(:first-child) {
      margin-top: 40px;
    }

    @include xs {
      font-size: 16px;
      line-height: 150%;
    }
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--secondary-grey-text);
    margin-top: 16px;

    @include xs {
      font-size: 15px;
      line-height: 150%;
    }
  }
}
