.advantages-card {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @include md {
    gap: var(--gap);
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__desc {
    color: var(--secondary-grey-text);
  }
}
