.filter-list {
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 16px 0;

  &::-webkit-scrollbar {
    width: 4px;
    height: 40px;
  }

  &::-webkit-scrollbar-track {
    background: var(--secondary-bg);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--secondary-grey);
  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-grey-text);
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 14px;
  }

  &__checkbox {
    display: none;
  }

  &__title {
    margin: 0;
    width: 100%;
  }

  &__checkbox + &__title {
    position: relative;
    padding-left: 32px;
    cursor: pointer;
  }

  &__checkbox + &__title:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    outline: 1px solid var(--secondary-grey);
    outline-offset: -1px;
    background-color: var(--primary-white);
    border-radius: var(--border-radius-5);
  }

  &__checkbox:checked + &__title:before {
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5617 3.47199L4.54175 9.49194L1.43848 6.38866L2.29781 5.52933L4.54175 7.77328L9.70236 2.61266L10.5617 3.47199Z' fill='%23fff'/%3E%3C/svg%3E");
    background-size: 100% 100%;
    border: 1px solid var(--primary-orange);
    background-color: var(--primary-orange);
    outline: none;
  }

  &__checkbox:disabled + &__title {
    opacity: 0.3;
    text-decoration: line-through;
  }

  &__reset {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    background-color: transparent;
    border: 1px solid var(--secondary-grey-text);
    border-radius: 8px;
    padding: 10px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 125%;
    color: var(--secondary-grey-text);
    margin-top: 24px;

    svg {
      width: 18px;
      height: 18px;
      fill: var(--secondary-grey-text);
    }
  }
}
