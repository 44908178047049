.modal-menu {
  &__overlay {
    justify-content: flex-start;
    align-items: unset;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    border-radius: 0 var(--border-radius-5) var(--border-radius-5) 0;
    padding: var(--gap);
    width: 492px;

    @include xs {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }

  &__btns {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    .btn {
      flex: 1;
      justify-content: space-between;
    }

    @include xs {
      flex-direction: column;
      gap: 8px;

      .btn {
        width: 100%;
      }
    }
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: relative;

    .modal-menu__block:last-child {
      position: absolute;
      right: 0;
      bottom: 0;

      @include xs {
        position: relative;
      }
    }
  }

  &__block {
    .city-choose {
      svg.icon-arrow-down {
        transform: rotate(-90deg);
      }
    }
  }
}
