.services {

  &__inner {
    display: flex;
    flex-direction: column;
    gap: 140px;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h3 {
      width: 100%;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--secondary-light-grey);
    }
  }

  &__row {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    flex-wrap: wrap;

    @include md {
      justify-content: center;
    }
  }
}