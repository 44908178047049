.advantages {
  background-image: url("../assets/images/about-background.png");
  background-repeat: no-repeat;
  background-position: center;

  @include lg {
    background-image: none;
  }

  &__title {
    margin-bottom: 60px !important;
  }

  &__wrapper {
    background-color: var(--primary-white);
    width: 100%;
    height: 100%;
    padding: 14px 110px 0 110px;

    @include lg {
      padding: 0;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 80px var(--gap);

    @include md {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 40px;
    }
  }

  &__more {
    width: max-content;
    margin-top: auto;
    cursor: pointer;
    margin-left: var(--gap);
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }

    @include md {
      margin-left: 0;
    }
  }

  &__item {
    position: relative;

    &:last-child {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      border-left: 1px solid var(--secondary-grey);

      @include md {
        border-left: none;
      }
    }
  }
}