.service-table {
  &__inner {
    overflow-x: auto;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__wrapper {
    width: 100%;
  }

  &__table {
    border-top: 1px solid var(--secondary-light-grey);
    border-bottom: 1px solid var(--secondary-light-grey);
    padding: 24px 0;
    width: 100%;
    min-width: 522px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    &_row {
      display: grid;
      row-gap: 40px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      p {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: var(--primary-black);

        @include md {
          font-size: 15px;
          text-align: center;
        }
      }

      &:first-child {
        p {
          font-family: var(--font-family);
          font-weight: 400;
          font-size: 13px;
          line-height: 150%;
          text-transform: uppercase;
          color: var(--secondary-grey-text);
        }
      }
    }

  }
}