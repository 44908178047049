.short-manager {
  display: flex;
  gap: 16px;
  width: 100%;
  max-width: 300px;

  &__avatar {
    width: 80px;
    min-width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__info {
    width: max-content;
  }

  &__title {
    margin-bottom: 8px;

    @include md {
      min-width: 114px;
      font-size: 14px;
    }
  }

  &__callback {
    width: max-content;
    padding-bottom: 4px;
    color: var(--secondary-grey-text);
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }
}