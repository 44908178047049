.note-block {
  &__wrapper {
    display: flex;

    @include lg {
      flex-direction: column;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 415px;
    padding-bottom: var(--gap);
    border-right: 1px solid var(--secondary-light-grey);

    @include lg {
      padding-top: var(--gap);
      width: 100%;
      flex-direction: row;
      border-right: none;
      border-bottom: 1px solid var(--secondary-light-grey);
    }

    @include sm {
      flex-direction: column;
      gap: 40px;
    }
  }

  &__right {
    padding-left: var(--gap);
    flex: 1;

    .more-btn {
      margin-top: 48px;
      display: none;

      svg {
        stroke: var(--secondary-grey-text);
      }
    }

    @include lg {
      padding-left: 0;
      padding-top: var(--gap);
    }
  }

  &__text {
    & *:first-child {
      margin-top: 0;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: var(--secondary-grey-text);
      margin-top: 12px;
    }

    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-transform: uppercase;
      margin-top: 36px;
    }
  }

  &__subtitle {
    padding-right: var(--gap);

    @include lg {
      padding-right: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }
}
