.city-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  &__item {
    border-radius: var(--border-radius-5);
    padding: 7px 12px;
    background-color: var(--secondary-bg);
    color: var(--primary-black);
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:not(.active) {
      &:hover {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }

    &.active {
      color: var(--primary-white);
      background-color: var(--primary-orange);
    }
  }
}