.map-rf {
  svg {
    path {
      &:not(:first-child):hover {
        stroke: var(--primary-orange);
        stroke-width: 2px;
      }
      &.active {
        fill: rgba(255, 92, 27, 0.1);
        stroke: var(--primary-orange);
        stroke-width: 2px;
      }
    }
  }

  &__wrapper {
    position: relative;
  }

  &__window {
    position: absolute;
    bottom: 0;
    left: 192px;
    border-radius: 5px;
    padding: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.05);
    background-color: var(--primary-white);
    display: flex;
    gap: 16px;

    @include lg {
      display: none;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--primary-black);
    width: 206px;
  }
}
