.product-tabs {
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: var(--primary-black);
      margin-top: var(--gap);

      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__subtitle {
    margin-bottom: 16px;
  }
}
