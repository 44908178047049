h2 {
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
  text-transform: uppercase;
  color: #151515;
  margin-bottom: var(--gap);

  @include lg {
    font-size: 24px;
  }

  @include xs {
    font-size: 16px;
    line-height: 150%;
  }
}

.heading {
  &-1,
  &-2,
  &-3,
  &-4,
  &-5 {
    font-weight: 700;
    text-transform: uppercase;
    color: var(--primary-black);
  }

  &-1 {
    font-size: 80px;
    line-height: 110%;
  }

  &-2 {
    font-size: 50px;
    line-height: 110%;
  }

  &-3 {
    font-size: 40px;
    line-height: 120%;
  }

  &-4 {
    font-size: 28px;
    line-height: 120%;
  }

  &-5 {
    font-size: 24px;
    line-height: 120%;
  }
}

.subtitle {
  &-1,
  &-2,
  &-3,
  &-4 {
    font-weight: 700;
    line-height: 150%;
    text-transform: uppercase;
  }

  &-1 {
    font-size: 20px;
  }

  &-2 {
    font-size: 18px;
  }

  &-3 {
    font-size: 16px;
  }

  &-4 {
    font-size: 13px;
  }
}

.body {
  &-1,
  &-2,
  &-3,
  &-4,
  &-5,
  &-6 {
    font-weight: 400;
    line-height: 150%;
    color: var(--primary-black);
  }

  &-1 {
    font-size: 20px;
  }

  &-2 {
    font-size: 18px;
  }

  &-3 {
    font-size: 16px;
  }

  &-4 {
    font-size: 15px;
  }

  &-5 {
    font-size: 14px;
  }

  &-6 {
    font-size: 13px;
    text-transform: uppercase;
  }
}

.caption {
  &-1 {
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--primary-black);
  }
}
