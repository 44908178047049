.service-top {
  &__inner {
    display: grid;
    gap: 24px;
    grid-template-columns: 416px auto 306px;
    grid-template-areas:
      "img info list";

    @include lg {
      grid-template-columns: auto 1fr;
      grid-template-rows: 1fr 1fr;
      grid-template-areas:
        "img info"
        "list list";
    }

    @include md {
      grid-template-columns: 216px 1fr;
    }

    @media (max-width:600px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto;
      grid-template-areas:
        "img"
        "info"
        "list";
    }
  }

  &__block {
    h4 {
      margin-bottom: 24px;

      @include md {
        font-size: 16px;
      }
    }

    img {
      @media (max-width:600px) {
        width: 100%;
      }
    }

    ul {
      margin-bottom: 60px;

      @include md {
        margin-bottom: 24px;
      }

      li {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 15px;
        line-height: 150%;
        color: var(--secondary-grey-text);

        @include md {
          font-size: 14px;
        }
      }
    }

    &.img {
      grid-area: img;
    }

    &.info {
      grid-area: info;
    }

    &.list {
      grid-area: list;
    }


  }

  &__btn {}

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 5px;
    padding: 24px;
    background: var(--secondary-bg);

    @include lg {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include md {
      grid-template-columns: 1fr 1fr;
    }

    @include xs {
      grid-template-columns: 1fr;
    }

    &_item {
      display: flex;
      gap: 10px;

      svg {
        min-width: 24px;
      }
    }

    &_text {
      display: flex;
      flex-direction: column;
      gap: 2px;

      p {
        margin: 0;
      }
    }
  }
}