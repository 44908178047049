.social-list {
  display: flex;
  align-items: center;
  gap: 12px;

  &__item {
    display: flex;
    padding: 8px;
    border-radius: var(--border-radius-5);
    background-color: var(--secondary-bg);
  }
}
