@mixin xl {
  @media (min-width: 1401px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1400px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 1023px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin xs {
  @media (max-width: 500px) {
    @content;
  }
}

@mixin xxs {
  @media (max-width: 340px) {
    @content;
  }
}
