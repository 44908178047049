.product-header {
  margin-bottom: var(--gap);

  &__wrapper {
    display: flex;
    justify-content: space-between;
    gap: var(--gap);

    @include lg {
      flex-wrap: wrap;
    }

    @include sm {
      flex-direction: column;
      align-items: center;
    }
  }

  &__info {
    width: 100%;
  }

  &__photo,
  &__info {
    flex: 1;
  }

  &__photo {
    width: 100%;
    max-width: 416px;
    height: 416px;

    @include lg {
      max-width: 296px;
      height: 296px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__aside {
    padding: var(--gap);
    background-color: var(--secondary-bg);
    border-radius: var(--border-radius-5);

    @include lg {
      width: 100%;
    }
  }
}
