html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

ul,
li {
	list-style: none;
	margin: 0;
	padding: 0;
}

a {
	color: inherit;
	text-decoration: none;
}

button,
input,
textarea {
	background: transparent;
	border: none;
}

button {
	cursor: pointer;
	outline: none;
}

input,
textarea {
	outline: none;
}

textarea {
	resize: none;
}

img {
	max-width: 100%;
}

svg {
	display: block;
}

fieldset {
	padding: 0;
	border: none;
}

table {
	border-spacing: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
	appearance: none;
	-moz-appearance: textfield;
}