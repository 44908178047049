.footer-list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__title {
    color: var(--secondary-grey-text);
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__link {
    color: var(--primary-white);
    text-decoration: none;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }
}