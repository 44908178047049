.collapse {

  &,
  &__wrapper,
  &__item {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__item {
    &.open {
      .collapse__body {
        padding: 16px 16px 16px var(--gap);
      }

      .collapse__btn {
        background-color: var(--primary-orange);

        .icon-arrow-right {
          fill: var(--primary-white);
          transform: rotate(-90deg);
          transition: all .2s ease-in-out;
        }
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap);
    padding: 16px 16px 16px var(--gap);
    background-color: var(--secondary-bg);
    border-radius: var(--border-radius-5);
    cursor: pointer;

    .icon-arrow-right {
      fill: var(--primary-black);
      transform: rotate(90deg);
      transition: all .2s ease-in-out;
    }
  }

  &__title {
    @include xs {
      font-size: 14px;
    }
  }

  &__body {
    padding: 0;
    border: 1px solid var(--secondary-light-grey);
    border-radius: var(--border-radius-5);

    @include xs {
      font-size: 14px;
    }
  }

  &__btn {}
}