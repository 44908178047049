.breadcrumbs {
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  &__list {
    display: flex;
    align-items: center;
    gap: 28px;
    padding: 0;
    margin: 0;
    list-style: none;
    width: max-content;
  }

  &__item {
    position: relative;

    &:not(:last-child)::after {
      content: "";
      position: absolute;
      width: 4px;
      height: 4px;
      background-color: var(--primary-orange);
      border-radius: 50%;
      transform: translateY(-50%);
      top: 50%;
      right: -16px;
    }
  }

  a {
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  &__item,
  &__link {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--secondary-grey-text);
    text-decoration: none;
    z-index: 1;

  }
}