.modal-callback {
  @include xxs {
    .modal__title {
      max-width: 240px;
    }
  }

  &__wrapper,
  &__header,
  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__wrapper {
    background-color: var(--secondary-bg);
  }

  &__container {
    max-width: 700px;

    @include sm {
      margin: 0 14px;
    }
  }

  &__desc {
    color: var(--secondary-grey-text);
    width: 100%;
    max-width: 400px;
  }

  &__content {
    .form {
      @include xs {
        flex-direction: column;
      }
    }
  }

  &__input {
    background-color: var(--primary-white);
  }
}
