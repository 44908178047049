.form {

  &,
  &__header,
  &__body,
  &__footer {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__body {
    @include lg {
      flex-direction: row;
      flex-wrap: wrap;

      .vacancies-now__input {
        width: 31%;
      }

      .checkout-now__input {
        width: 40vw;
      }
    }

    @media (max-width: 840px) {
      flex-direction: column;
      flex-wrap: nowrap;

      .vacancies-now__input {
        width: 100%;
      }

      .checkout-now__input {
        width: 100%;
      }
    }
  }

  &__desc {
    color: var(--secondary-grey-text);
    opacity: 0.7;
  }

  @include sm {
    width: 100%;
    max-width: 100%;
  }
}

.directly-now {
  display: flex;
  background-color: var(--primary-white);
  border-radius: var(--border-radius-5);
  padding: 40px;
  width: 100%;
  max-width: 610px;

  &__form {
    width: 100%;
  }

  &__title {
    padding-right: 100px;

    @include lg {
      padding-right: 0;
    }

    @include md {
      font-size: 20px;
      line-height: 150%;
    }
  }

  @include lg {
    max-width: 456px;

    .form-submit {
      flex-direction: column;

      .btn {
        max-width: 100% !important;
      }
    }
  }

  @include md {
    padding: 24px;
    max-width: 328px;
  }

  @include sm {
    max-width: 100%;
  }

  @include xs {
    padding: 0;
  }
}