.catalog-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  gap: 8px;

  a {
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  &__item:first-child {
    margin-bottom: 8px;
  }

  &__item {
    &.hidden {
      display: none;
    }
  }

  &__more {
    display: none;
    align-items: center;
    gap: 4px;
    margin-top: 46px;
    cursor: pointer;

    &.visible {
      display: flex;
    }

    svg {
      stroke: var(--secondary-grey-text);
    }
  }
}