.order-block {
  &__products,
  &__services,
  &__content {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__products {
    padding-bottom: var(--gap);
    border-bottom: 1px solid var(--secondary-grey);
  }

  &__services {
    padding-top: var(--gap);

    @include sm {
      & .basket-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-column-gap: 0px;
        grid-row-gap: 0px;

        .basket-card__info {
          grid-area: 1 / 1 / 2 / 3;
        }
        .pricing-block {
          grid-area: 2 / 1 / 3 / 2;
          padding: 24px 0 0 0;
        }
        .product-actions {
          grid-area: 2 / 2 / 3 / 3;
          padding-top: 12px;
        }
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: var(--gap);
    justify-content: space-between;
  }

  &__clear {
    color: var(--secondary-grey-text);
    cursor: pointer;
  }
}
