.promo-input {
  position: relative;

  &__input {
    width: 100%;
  }

  &__btn {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
