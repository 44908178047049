.intro {
  position: relative;
  height: calc(100vh - 157px);
  max-height: 900px;
  padding: 0;

  @include lg {
    height: 600px;
  }

  @include md {
    height: 500px;
  }

  @include sm {
    padding-bottom: 12px;
    height: fit-content;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 14%;
    background: url('../assets/images/main-bg.png') no-repeat bottom center;
    background-size: contain;
    width: 50%;
    height: 509px;

    @include md {
      right: 6%;
      width: 53%;
    }

    @include sm {
      width: 100%;
    }
  }

  &-grid {
    display: grid;
    gap: 24px;
    grid-template-columns: 306px 1fr;
    position: relative;
    height: 100%;
    padding: 24px 0;
    z-index: 2;

    @include sm {
      display: flex;
      flex-direction: column-reverse;
      gap: 16px;
    }
  }

  &-wrapper {}

  &__title {}

  &__imgs {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 66%;

    @include md {
      width: 60%;
    }

    @include sm {
      width: 100%;
    }
  }

  &__img {
    display: none;

    &.active {
      display: block;
    }
  }
}

.intro-list {
  display: flex;
  flex-direction: column;
  padding-right: 24px;
  border-right: 1px solid var(--secondary-grey);

  @include sm {
    padding-right: 0;
    border: none;
  }

  &__item {
    padding: 12px 0;
    border-bottom: 1px solid var(--secondary-grey);
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      .intro-list__item_top p {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }

    &.active {
      >p {
        visibility: visible;
        height: 18px;
        transition: all .2s ease-in-out;
      }

      .intro-list__item_top {
        p {
          color: var(--primary-orange);
          transition: all .2s ease-in-out;
        }

        .intro-list__item_arrow {
          opacity: 1;
          transition: all .2s ease-in-out;
        }
      }
    }

    >p {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: var(--primary-black);
      visibility: hidden;
      height: 0px;
      overflow: hidden;
      transition: all .2s ease-in-out;
    }

    &_top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 16px;
        line-height: 150%;
        text-transform: uppercase;
        color: var(--primary-blue);
        transition: all .2s ease-in-out;
      }
    }

    &_arrow {
      opacity: 0;
      transition: all .2s ease-in-out;
    }
  }
}