.services__item {
  display: flex;
  flex-direction: column;
  max-width: 306px;

  p {
    transition: all .2s ease-in-out;
  }

  &:hover {
    p {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  &_img {}

  &_text {
    padding: 32px;
    background: var(--secondary-bg);
    border-radius: 5px;
    height: 146px;
    display: flex;
    align-items: flex-end;
  }
}