.discount-banner {
  border-radius: 5px;
  padding: 24px;
  background: var(--secondary-bg);
  height: 420px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  @include lg {
    height: fit-content;
    padding: 24px 24px 24px 40px;
    flex-direction: row;
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    background: url('../assets/images/discount-banner-bg.png') no-repeat center center;
    width: 262px;
    height: 318px;

    @include lg {
      height: 100%;
      width: 583px;
      background-size: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-position: 0 26%;
    }
  }

  h3 {
    position: relative;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 20px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--primary-black);
    z-index: 1;
    margin: 0;
  }

  p {
    position: relative;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: var(--secondary-grey-text);
    z-index: 1;

    span {
      font-weight: 700;
      font-size: 50px;
      line-height: 110%;
      text-transform: uppercase;
      color: var(--primary-orange);
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__btn {
    position: relative;
    z-index: 1;
  }
}