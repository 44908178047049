.listing {
  width: 100%;

  &,
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);

  }

  &__wrapper {}
}