.how-card {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__info {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__desc {
    color: var(--secondary-grey-text) !important;
  }
}
