.service-tabs {
  &__header {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 60px;
  }

  &__content {
    max-width: 856px;

    h2 {
      margin-bottom: 16px;
    }

    p {
      margin-top: 0;
      margin-top: 24px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: var(--secondary-grey-text);

      &:first-child {
        margin-top: 0;
      }
    }

    img {
      margin: 40px 0;
    }

    ul {
      li {
        list-style: disc;
        margin-left: 24px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: var(--secondary-grey-text);
      }
    }

    &.hidden {
      display: none;
    }
  }
}