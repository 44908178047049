.pre-footer {
  padding: var(--gap) 0;
  background: var(--secondary-bg);

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 440px;

    @include md {
      grid-template-columns: 215px 1fr 1fr;
    }

    @include sm {
      display: flex;
      flex-direction: column;

      .btn {
        width: 100%;
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: var(--gap);
    padding: var(--gap);

    &--first {
      padding-left: 0;

      @media (max-width: 1300px) {
        width: 296px;
      }

      @include md {
        width: 240px;
      }

      @include sm {
        margin-bottom: 24px;
      }
    }

    &--second {
      padding-left: 0;
      margin-right: var(--gap);

      @media (max-width: 1300px) {
        padding-right: 0px;
        padding-left: var(--gap);
        margin-right: 48px;
      }

      @include md {
        padding-left: 0;
        padding-right: 0;
        margin-right: var(--gap);
      }

      @include sm {
        margin-bottom: 40px;
      }
    }

    &--last {
      padding-right: 0;
      border-left: 1px solid var(--secondary-grey);

      @include sm {
        gap: 16px;
        border-left: none;
      }
    }

    @include sm {
      width: 100%;
      padding: 0;
    }
  }

  &__title {
    width: 416px;
    padding-right: 150px;


    @media (max-width: 1300px) {
      font-size: 28px;
      padding-right: 210px;
    }

    @include sm {
      font-size: 24px;
      width: 100%;
      padding-right: 0;
    }
  }

  &__desc {
    @media (max-width: 1300px) {
      font-size: 15px;
    }

    @include md {
      font-size: 12px;
    }

    @include sm {
      font-size: 15px;
    }
  }

  &__subtitle {
    color: var(--secondary-grey-text);
  }

  &__contacts {
    .short-contact a {
      font-size: 16px;
      transition: all .2s ease-in-out;

      &:hover {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }
  }
}