.product-note {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__item {
    display: flex;
    gap: 10px;
  }

  &__desc {
    color: var(--secondary-grey-text) !important;
  }

  @include lg {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--gap) 0;
  }

  @include lg {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gap) 20px;
  }

  @include xs {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
