.mega-menu {
  display: none;
  padding: 24px 0;
  position: absolute;
  top: 159px;
  left: 0;
  width: 100%;
  background: var(--secondary-bg);
  z-index: 12;

  @include md {
    top: 0;
  }

  &.active {
    display: block;
  }

  &__header {
    display: none;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 24px;

    @include md {
      display: flex;
    }

    &_left {
      display: flex;
      gap: 32px;
      align-items: center;

      @media (max-width:440px) {
        .toggler {
          display: none;
        }
      }
    }

    &_back {
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: 5px;
      padding: 12px;
      background: var(--secondary-grey);
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 13px;
      line-height: 120%;
      text-transform: uppercase;
      color: var(--primary-black);

      @media (max-width:440px) {
        font-size: 18px;
        line-height: 150%;
        padding: 0;
        border-radius: 0;
        background: transparent;
      }
    }

    &_close {}
  }

  &__inner {
    display: grid;
    grid-template-columns: 306px 1fr;
    gap: 0 24px;

    @media (max-width:600px) {
      grid-template-columns: 1fr;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 32px;
    border-right: 1px solid var(--secondary-grey);
    padding-right: 12px;

    @media (max-width:600px) {
      padding-right: 0;
      border: none;
    }

    @media (max-width:440px) {
      align-items: center;
    }

    .toggler {
      padding-left: 12px;

      @include md {
        display: none;
      }

      @media (max-width:440px) {
        display: flex;
      }
    }

    &_list {
      display: flex;
      flex-direction: column;

      @media (max-width:440px) {
        width: 100%;
      }
    }

    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px;
      transition: all .2s ease-in-out;
      border-radius: 5px;

      &:hover {
        background: var(--primary-white);
        transition: all .2s ease-in-out;
      }

      &.active {
        background: var(--primary-white);
      }

      p {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: var(--primary-black);
      }
    }
  }

  &__right {
    @media (max-width:600px) {
      display: none;
    }

    &_grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 24px;
      grid-template-rows: auto auto;

      @include lg {
        grid-template-columns: repeat(2, 1fr);
        max-height: 80vh;
        overflow-y: auto;
      }

      @include md {
        grid-template-columns: 1fr;
      }

    }

    &_block {
      height: fit-content;
    }

    &_category {
      border-radius: 5px;
      padding: 12px;

      p {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 20px;
        line-height: 150%;
        text-transform: uppercase;
        color: var(--primary-black);
      }
    }

    &_list {
      a {
        display: block;
        border-radius: 5px;
        padding: 8px 12px;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        color: var(--primary-black);
        transition: all .2s ease-in-out;

        &:hover {
          color: var(--primary-orange);
          transition: all .2s ease-in-out;
        }

        @include xs {
          font-size: 15px;
        }
      }
    }
  }
}

.mega-menu-overlay {
  display: none;
  position: fixed;
  top: 159px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--primary-black);
  opacity: 0.6;
  z-index: 12;

  &.active {
    display: block;
  }
}