.basket-card {
  &,
  &__info {
    display: flex;
    align-items: center;
    gap: var(--gap);
  }

  justify-content: space-between;

  @include sm {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;

    .pricing-block {
      padding: 12px 0 16px;
    }

    .product-actions--basket,
    .product-actions--basket-service {
      max-width: 100%;
    }
  }

  &__info {
    width: 100%;
    max-width: 500px;

    @include md {
      max-width: 290px;
    }

    @include sm {
      max-width: 100%;
      gap: 16px;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--secondary-grey);
    }
  }

  background-color: var(--secondary-bg);
  border-radius: var(--border-radius-5);
  padding: 16px;

  &__photo {
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: var(--border-radius-5);
    overflow: hidden;

    @include md {
      width: 48px;
      min-width: 48px;
      height: 48px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
