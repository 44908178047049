.catalog {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 60px;
  }

  &__header {
    padding-bottom: var(--gap);
    border-bottom: 1px solid var(--secondary-light-grey);
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px var(--gap);

    @include md {
      grid-template-columns: repeat(2, 1fr);
    }

    @include xs {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 60px;
    }
  }

  &__item {
    padding-bottom: var(--gap);
    border-bottom: 1px solid var(--secondary-light-grey);
  }
}
