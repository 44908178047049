.filter-range {
  &__label {
    font-size: 14px;
    font-weight: 500;
  }

  &__price {
    width: 100%;
    display: flex;
    margin-bottom: 16px;
  }

  &__range {
    position: relative;

    input {
      position: absolute;
      width: 100%;
      height: 5px;
      top: -5px;
      background: none;
      pointer-events: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  &__fields {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 8px;

    &--item {
      flex: 1;
    }

    input {
      width: 100%;
      font-size: 14px;
      padding: 8px 12px;
      -moz-appearance: textfield;
      border-radius: var(--border-radius-5);
      border: 1px solid var(--secondary-light-grey);
    }
  }

  &__slider {
    height: 5px;
    position: relative;
    background-color: var(--secondary-light-grey);
    border-radius: 5px;

    &-progress {
      height: 100%;
      left: 25%;
      right: 25%;
      position: absolute;
      border-radius: 5px;
      background-color: var(--primary-orange);
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 12px;
    width: 12px;
    border: 4px solid var(--primary-orange);
    border-radius: 50%;
    background: var(--primary-orange);
    pointer-events: auto;
    -webkit-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }

  input[type="range"]::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border: none;
    border-radius: 50%;
    background: #17a2b8;
    pointer-events: auto;
    -moz-appearance: none;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
  }
}
