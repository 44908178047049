.pagination {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--gap);
  border-top: 1px solid var(--secondary-light-grey);
  gap: 20px;

  &__list {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__item {
    padding: 8px 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 133%;
    color: var(--primary-black);
    cursor: pointer;

    &:hover {
      color: var(--primary-orange);
    }

    &.active {
      color: var(--primary-orange);
      border-bottom: 1px solid var(--primary-orange);
    }

    @include xs {
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }
  }

  &__btn {
    &--left {
      transform: rotate(180deg);
    }

    @include xs {
      width: 24px;
      height: 24px;
      overflow: hidden;
      padding: 0 !important;
      background: none !important;
    }
  }
}
