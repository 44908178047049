.filter-search {
  display: none;
  position: relative;

  &.visible {
    display: block;
  }

  &__input {
    width: 100%;

    &.input {
      padding: 14px 14px 14px 48px;
    }
  }

  &__submit {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);

    svg {
      fill: var(--secondary-grey-text);
    }
  }

  &__no-results {
  }
}
