.count-card {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: var(--primary-white);
  border-radius: 10px;
  padding: var(--gap);

  @include md {
    gap: 4px;
  }

  &__value {
    color: var(--primary-orange);

    @include lg {
      font-size: 28px;
    }

    @include md {
      font-size: 20px;
      line-height: 150%;
    }

    @include xs {
      font-size: 24px;
      line-height: 120%;
    }
  }

  &__title {
    @include md {
      font-size: 12px;
    }
  }
}
