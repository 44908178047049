* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  // height: 100%;
  padding: 0;
  margin: 0;
  // overflow-x: hidden;

  &.no-scroll {
    overflow: hidden;
  }
}

a {
  text-decoration: none;
  color: var(--primary-black);
}

ul {
  padding: 0;
  list-style: none;
}

h1 {
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 80px;
  line-height: 110%;
  text-transform: uppercase;
  color: var(--primary-black);
  margin: 0;

  @include md {
    font-size: 50px;
  }

  @include sm {
    font-size: 28px;
    line-height: 120%;
  }
}

.page {
  // display: grid;
  // grid-template-rows: min-content 1fr min-content;
  width: 100%;
  // height: 100%;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: var(--primary-black);

  &:has(.not-found) {
    background-color: var(--secondary-bg);

    .header {
      margin: 0;
    }
  }

  @include xs {
    font-size: 15px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

// Кнопки

.fast-buy {
  width: 200px;
}

.more-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  &.open {
    svg {
      transform: rotate(180deg);
      stroke: var(--secondary-grey-text);
    }
  }
}

// Заголовки, позаголовки

.page-title {
  font-weight: 700;
  font-size: 50px;
  line-height: 110%;
  text-transform: uppercase;
  color: var(--primary-black);
  margin: 0;
  max-width: 912px;

  @include lg {
    font-size: 40px;
    line-height: 120%;
  }

  @include xs {
    font-size: 20px;
    line-height: 150%;
  }
}

// Остальное

.product-detail {
  display: flex;
  flex-direction: column;
}

.underline {
  border-bottom: 1px solid var(--primary-orange);
}

.section {
  &__title {
    padding-bottom: 23px;
    margin-bottom: var(--gap);
    border-bottom: 1px solid var(--secondary-light-grey);
  }

  &__footer {
    margin-top: var(--gap);
    padding-top: 20px;
    border-top: 1px solid var(--secondary-light-grey);
  }
}

.dn-lg {
  @include lg {
    display: none;
  }
}

.form__blocks {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  padding: 40px 60px;
  background: var(--secondary-bg);

  @include lg {
    padding: 40px;
    flex-direction: column;
  }

  @include xs {
    padding: 24px 12px;
  }
}

.form__block {
  width: 50%;

  @include lg {
    width: 100%;
  }

  &:nth-child(1) {
    margin-right: 24px;

    @include lg {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  &:nth-child(2) {
    padding-left: 24px;
    border-left: 1px solid var(--secondary-light-grey);

    @include lg {
      padding-left: 0;
      border-left: transparent;
      padding-top: 24px;
      border-top: 1px solid var(--secondary-light-grey);
    }
  }
}

.accordion {
  &-content {
    height: 0;
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    box-sizing: content-box;
    transition: height 0.2s ease-in-out, opacity 0.1s ease-in-out,
      visibility 0.1s ease-in-out;
  }

  &-trigger {
    @include xs {
      p {
        font-size: 16px;
      }
    }
  }

  &.open {
    .accordion-content {
      opacity: 1;
      visibility: visible;
      box-sizing: content-box;
    }

    .accordion-trigger {
      svg {
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
      }
    }
  }
}

.hidden {
  display: none;
}

button.tab {
  border-radius: 5px;
  padding: 12px 24px;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--primary-black);
  border: 1px solid transparent;
  transition: all 0.2s ease-in-out;

  @include xs {
    padding: 8px 20px;
    font-size: 13px;
  }

  &.active {
    color: var(--primary-orange);
    border: 1px solid var(--primary-orange);
    transition: all 0.2s ease-in-out;
  }
}

.colored {
  color: var(--primary-orange);
}

.btn-border-orange {
  position: relative;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--primary-black);
  transition: all .2s ease-in-out;

  &:hover {
    color: var(--primary-orange);
    transition: all .2s ease-in-out;
  }

  &::before {
    content: "";
    position: absolute;
    top: 150%;
    left: 0;
    background: var(--primary-orange);
    height: 1px;
    width: 100%;
  }
}

#swiper-services-slider {
  margin-bottom: 20px;
}

.content-search {
  @include lg {
    .template-grid__aside {
      display: block;
    }
  }

  @include sm {
    .template-grid__aside {
      display: none;
    }
  }
}

// yndex-map

[class*="copyrights-pane"] {
  display: none !important;
}

[class*="ground-pane"] {
  filter: grayscale(1);
}

.sticky {
  position: sticky;
  top: 40px;
  margin-bottom: 40px;
}

.content-product .template-grid__aside,
.content-vacancy .template-grid__aside,
.content-contacts .template-grid__aside {
  @include lg {
    display: block;
  }
}