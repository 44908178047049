.shipments-list {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__wrapper {
    display: grid;
    grid-auto-rows: auto;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    @include xs {
      grid-template-columns: 1fr;
    }

    .work__slider {
      border: none;
      padding: 0;
    }
  }
}