.header-callback {
  display: flex;
  align-items: center;
  gap: 16px;

  @include lg {
    align-items: flex-end;
    flex-direction: column-reverse;
    gap: 0;
  }

  &__item {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--primary-black);
    cursor: pointer;


    &:last-child {
      position: relative;
      margin-bottom: 14px;
      transition: all .2s ease-in-out;

      &:before {
        content: "";
        position: absolute;
        top: 110%;
        width: 100%;
        height: 1px;
        background: var(--primary-orange);
      }

      &:hover {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }

    @include lg {
      &:last-child {
        display: none;
      }
    }

    &:nth-child(2) {
      padding-bottom: 14px;

      @include lg {
        padding-bottom: 0;
      }
    }

    a {
      color: var(--primary-black);
      text-decoration: none;
      transition: all .2s ease-in-out;

      &:hover {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }
  }
}