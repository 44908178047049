.requisites {
  tr {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: var(--secondary-light-grey);
    }

    @include sm {
      display: flex;
      flex-direction: column;
    }
  }

  th {
    padding: 16px 12px 16px 0;
    text-align: start;
    width: 416px;
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--secondary-grey-text);

    @include lg {
      width: 296px;
    }

    @include md {
      width: 228px;
    }

    @include sm {
      width: unset;
      padding: 12px var(--gap) 0 0;
    }
  }

  td {
    padding: 16px 0 16px 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;

    @include sm {
      padding: 0 var(--gap) 12px 0;
    }
  }
}
