.basket {
  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 306px;
    grid-gap: var(--gap);

    @include lg {
      display: flex;
      flex-direction: column;
      gap: 80px;
    }
  }
}
