.short-contact {
  display: flex;
  align-items: center;
  gap: 8px;

  &__icon {
    display: flex;

    svg {
      stroke: var(--primary-black);
    }
  }

  &__item a {
    color: var(--primary-black);
    text-decoration: none;
  }
}
