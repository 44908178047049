.shipments-cat {
  display: flex;
  flex-direction: column;
  gap: 32px;

  &__title {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--primary-black);
  }

  &__wrapper {
    &_title {
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 13px;
      line-height: 150%;
      text-transform: uppercase;
      color: var(--secondary-grey-text);
      margin-bottom: 12px;
    }
  }

  &__list {
    &_item {
      display: flex;
      gap: 12px;
      align-items: center;
      border-radius: 5px;
      padding: 12px;

      p {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: var(--primary-black);
        text-wrap: nowrap;
      }

      &.active {
        background: var(--secondary-bg);
      }
    }
  }
}