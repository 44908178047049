.in-stock {
  width: 100%;
  padding: var(--gap) 0;
  margin-bottom: 60px;
  border-bottom: 1px solid var(--secondary-grey);
  border-top: 1px solid var(--secondary-grey);

  tr {
    @include sm {
      display: flex;
      flex-direction: column;
      padding: var(--gap) 0;
      gap: var(--gap);

      &:first-child {
        display: none;
      }

      &:nth-child(2) {
        padding-top: 0;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  th {
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--secondary-grey-text);
    text-align: start;
    padding: 0 12px 26px 12px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      width: 310px;
    }

    @include md {
      &:nth-child(4) {
        display: none;
      }
    }
  }

  td {
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: var(--primary-black);
    padding: 12px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      width: 310px;
    }

    @include lg {
      font-size: 15px;
    }

    @include md {
      &:nth-child(3) {
        display: none;
      }
    }

    @include sm {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      padding: 0;

      &:nth-child(3) {
        display: flex;
      }

      &:not(:last-child) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
      }
    }

    .in-stock__heading,
    .in-stock__tabs {
      display: none;

      @include sm {
        display: flex;
      }
    }
  }

  &__tabs {
    display: flex;
    align-items: center;
    gap: 8px;

    @include xs {
      flex-wrap: wrap;

      .in-stock__tab-title {
        width: 100%;
      }
    }
  }

  &__tab {
    border-radius: 5px;
    padding: 2px 4px;
    background-color: var(--secondary-bg);
    cursor: pointer;

    &.active {
      background-color: var(--primary-orange);
      color: var(--primary-white);
    }
  }

  &__quantity {
    display: flex;
    align-items: center;
    gap: 8px;

    @include sm {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  &__label {
    position: relative;
    width: max-content;
  }

  &__input {
    padding: 12px 16px !important;

    @include xxs {
      max-width: 144px;
    }
  }

  &__deal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap);

    @include sm {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 0px;
      width: 100%;

      .in-stock__heading {
        grid-area: 1 / 1 / 2 / 2;
      }
      .in-stock__not-selected,
      .in-stock__selected {
        grid-area: 1 / 2 / 2 / 3;
      }
      .in-stock__incard {
        grid-area: 2 / 1 / 3 / 3;
      }
    }
  }

  &__not-selected {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: var(--secondary-grey-text);
  }

  &__selected {
    display: none;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: var(--primary-black);

    &.active {
      display: block;
    }
  }

  &__price-for {
    width: 40px;
    height: 20px;
    text-align: end;
    position: absolute;
    top: 12px;
    right: 0;
    transform: translateX(-50%);
  }

  &__incard {
    @include md {
      svg {
        display: none;
      }
    }

    @include sm {
      width: 100% !important;

      svg {
        display: block;
      }
    }
  }

  &__heading {
    color: var(--secondary-grey-text);
  }
}
