.product-actions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;

  a {
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  @include sm {
    justify-content: space-between;
  }

  &--listing {
    max-width: 239px;

    @include lg {
      max-width: 200px;
    }

    @include sm {
      max-width: 100%;

      .btn {
        gap: 8px;
      }
    }
  }

  &--basket,
  &--basket-service {
    max-width: 196px;

    @include sm {
      .btn {
        gap: 8px;
      }
    }

    @include xs {
      max-width: 100%;
    }

    .btn {
      padding: 8px;
    }
  }
}