.how-working {
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    border-bottom: 1px solid var(--secondary-light-grey);
    padding: var(--gap) 0;
    position: relative;

    @include lg {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 48px 0;

      &::after {
        position: absolute;
        content: "";
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 1px;
        background-color: var(--secondary-light-grey);
      }
    }

    @include sm {
      display: flex;
      flex-direction: column;
      gap: var(--gap);

      &::after {
        display: none;
      }
    }
  }

  &__item:first-child {
    padding-left: 0;
  }

  &__item:last-child {
    padding-right: 0;
  }

  &__item {
    position: relative;
    padding: 0 var(--gap);

    &:not(:last-child)::after {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: var(--secondary-light-grey);
    }

    @include lg {
      &:nth-child(2)::after {
        display: none;
      }
    }

    @include sm {
      padding: var(--gap) 0;
      border-top: 1px solid var(--secondary-light-grey);
      border-top: 1px solid var(--secondary-light-grey);

      &::after {
        display: none;
      }
    }
  }

  @include sm {
    .section-swiper__title {
      padding: 0;
      border: none;
    }

    .advantages-card {
      gap: var(--gap);
    }

    .advantages-card__info {
      gap: 8px;
    }
  }
}
