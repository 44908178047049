.modal-success {
  @include sm {
    .modal__title {
      font-size: 16px;
      line-height: 150%;
      text-align: center;
    }
  }

  &__icon {
    @include sm {
      width: 80px;
      height: 80px;
    }
  }

  &__container {
    max-width: 400px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include sm {
      align-items: center;
      gap: var(--gap);
    }
  }

  &__wrapper {
    background-color: var(--secondary-bg);
  }

  &__submite {
    justify-content: center !important;
  }
}
