.basket-form {
  &,
  &__block,
  &__section,
  &__final {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  @include lg {
    flex-direction: row;
    gap: var(--gap);
  }

  @include sm {
    flex-direction: column;
    gap: 60px;
  }

  &__section,
  &__final {
    gap: 20px;
  }

  &__section {
    padding: var(--gap);
    background-color: var(--secondary-bg);
    border-radius: var(--border-radius-5);
    width: 100%;
  }

  &__block {
    .promo-input {
      padding-bottom: 20px;
      border-bottom: 1px solid var(--secondary-grey);
    }
  }

  &__final {
    .btn {
      width: 100%;
      text-align: center;
      justify-content: center;
    }

    .form-submit__info {
      align-items: flex-start;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
  }

  &__text {
    &:not(.basket-form__final .basket-form__text) {
      color: var(--secondary-grey-text);
    }
  }
}
