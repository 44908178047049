.category-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--gap);
    padding: 16px 0;
    border-bottom: 1px solid var(--secondary-grey);
    transition: all .2s ease-in-out;

    &:hover {
      .category-list__title {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }

    @include xs {
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }

  &__count {
    color: var(--secondary-grey-text);
  }
}