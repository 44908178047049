.not-found {
  display: grid;
  grid-template-columns: 305px 1fr 330px;
  margin-top: 24px;

  @include lg {
    display: flex;
    flex-wrap: wrap;
  }

  @include sm {
    flex-direction: column;
  }

  &__left {
    padding-right: var(--gap);
    border-right: 1px solid var(--secondary-grey);

    @include lg {
      width: 100%;
      max-width: 300px;
    }

    @include md {
      max-width: 215px;
    }

    @include sm {
      max-width: 100%;
      padding-right: 0;
      padding-bottom: var(--gap);
      border-right: none;
      border-bottom: 1px solid var(--secondary-grey);
      margin-bottom: var(--gap);
    }
  }

  &__right {
    padding-left: var(--gap);
    border-left: 1px solid var(--secondary-grey);

    @include lg {
      padding-left: 0;
      border-left: 0;
      padding-top: 40px;
      border-top: 1px solid var(--secondary-grey);
      margin-top: 40px;
    }
  }

  &__error {
    text-align: right;
    color: var(--hover-grey);

    @include md {
      font-size: 40px;
      line-height: 120%;
    }

    @include sm {
      font-size: 28px;
      line-height: 120%;
      text-align: start;

      br {
        display: none;
      }
    }
  }

  &__content {
    padding: 0 var(--gap) 116px;
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    @include lg {
      padding: 0 var(--gap);
    }

    @include sm {
      padding: 0;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 50px;
    line-height: 110%;
    text-transform: uppercase;
    color: var(--primary-black);

    @include md {
      font-size: 40px;
      line-height: 120%;
    }

    @include xs {
      font-size: 28px;
      line-height: 120%;
    }
  }

  &__desc {
    color: var(--secondary-grey-text);

    @include xs {
      br {
        font-size: 15px;
        line-height: 150%;
        display: none;
      }
    }
  }

  &__search {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    max-width: 416px;

    svg path {
      stroke: var(--secondary-grey-text);
      fill: var(--secondary-grey-text);
    }

    .search-input {
      display: flex;

      .search-input__close {
        display: none;
      }
    }

    @include xs {
      max-width: 100%;
    }
  }

  &__back {
    display: flex;
    gap: var(--gap);

    .btn {
      justify-content: center;
    }

    @include xs {
      flex-direction: column;
      gap: 12px;

      .btn {
        max-width: 100%;
      }
    }
  }
}
