.contacts {
  display: flex;
  gap: var(--gap);

  a {
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  @include lg {
    justify-content: space-between;

    &__column:nth-child(2) {
      margin-left: auto;
    }
  }

  @include sm {
    flex-direction: column;
  }

  @include xs {
    gap: 40px;
  }

  &__column {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    width: 100%;
    max-width: 196px;

    @include lg {
      max-width: 216px;
    }

    @include md {
      max-width: 100%;
    }

    @include sm {
      width: 100%;
    }

    &--large {
      max-width: 416px;

      @include lg {
        max-width: 456px;
      }

      @include md {
        min-width: 286px;
        max-width: 100%;
      }
    }
  }
}