.modal-city {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);

    @include xs {
      width: 90%;
      padding: var(--gap) 0;

      .modal__header {
        padding: 0 var(--gap);
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 14px var(--gap);

    @include xs {
      display: flex;
      flex-direction: column;
      gap: 14px;
      height: 544px;
      overflow-y: auto;
      padding: 0 var(--gap);
    }

    &-item {
      width: 180px;
      position: relative;
      cursor: pointer;

      &:hover {
        color: var(--primary-orange);
      }

      &.active::after {
        content: "";
        position: absolute;
        top: 0;
        left: -20px;
        width: 16px;
        height: 16px;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.5232 6.91668C12.5232 9.54924 8.83987 13.2 7.75654 14.2833C6.0232 12.7667 2.98987 9.33257 2.98987 6.70002C2.98987 4.06746 5.12398 1.93335 7.75654 1.93335C10.3891 1.93335 12.5232 4.28413 12.5232 6.91668Z' fill='white' /%3E%3Cpath d='M7.58507 14.4108C7.64456 14.4702 7.72256 14.5 7.80051 14.5C7.87846 14.5 7.95646 14.4702 8.01595 14.4108C9.58669 12.84 11.1674 11.1265 12.0903 9.24028C13.2894 6.78935 13.0204 4.66966 11.2909 2.94016C10.3622 2.01147 9.12265 1.5 7.80051 1.5C6.47837 1.5 5.23882 2.01147 4.31011 2.94016C2.57085 4.67941 2.30633 6.81098 3.52389 9.27562C4.57237 11.3981 6.53219 13.3579 7.58507 14.4108ZM4.74101 3.37106C5.58452 2.52753 6.69251 2.10579 7.80053 2.10579C8.90855 2.10579 10.0166 2.52753 10.8601 3.37106C12.4068 4.91783 12.6302 6.7501 11.5429 8.97249C10.7007 10.6941 9.26125 12.2901 7.80023 13.7638C6.73804 12.6974 5.00764 10.9033 4.07022 9.00575C2.96575 6.77 3.18518 4.9269 4.74101 3.37106ZM7.79939 8.79247C8.9931 8.79247 9.96427 7.8213 9.96427 6.62759C9.96427 5.43387 8.9931 4.4627 7.79939 4.4627C6.60568 4.4627 5.63451 5.43387 5.63451 6.62759C5.63451 7.8213 6.60568 8.79247 7.79939 8.79247ZM7.79939 5.07208C8.65711 5.07208 9.3549 5.76987 9.3549 6.62759C9.3549 7.48528 8.65711 8.18309 7.79939 8.18309C6.94167 8.18309 6.24388 7.48531 6.24388 6.62759C6.24388 5.76987 6.94167 5.07208 7.79939 5.07208Z' fill='%23FF5C1B' stroke='%23FF5C1B' stroke-width='0.6' /%3E%3C/svg%3E");
      }

      @include xs {
        width: 100%;
      }
    }
  }
}
