.review-card {
  &,
  &__info {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__photo {
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__user {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__position {
    color: var(--secondary-grey-text);
  }

  &__text {
    color: var(--secondary-grey-text);
  }
}
