.shipments-slider {
  &__inner {}

  &__header {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--secondary-light-grey);
    margin-bottom: 24px;

    h2 {
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      text-transform: uppercase;
      color: var(--primary-black);
      margin: 0;
    }


  }

  &__sliders {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--secondary-light-grey);
    margin-bottom: 20px;

    @include lg {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }
  }

  &__wrapper {
    max-height: 250px;
    height: 100%;
    margin-bottom: 24px;
  }

  &__btn {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--primary-black);
    border-bottom: 1px solid var(--primary-orange);
    padding-bottom: 4px;
  }
}