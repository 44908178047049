.listing-vacancy {
  &__card {
    max-width: 306px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid var(--secondary-light-grey);
    border-radius: 5px;

    @include xs {
      max-width: 100%;
    }


    &_top {
      padding: 24px 32px 48px 32px;
    }

    &_wrapper {
      display: flex;
      align-items: center;
      gap: 4px;
      margin-top: 16px;
    }

    &_price {
      border-radius: 5px;
      padding: 16px 32px;
      background: var(--secondary-bg);
      transition: all .2s ease-in-out;
    }

    &:hover {
      .listing-vacancy__card_price {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }
  }
}