.search-not-found {
  border: 1px solid var(--secondary-light-grey);
  border-radius: 5px;
  padding: 110px;
  width: 100%;
  background: var(--primary-white);
  display: flex;
  gap: 40px;
  flex-direction: column;

  &__top {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h4 {
      margin: 0;
      font-family: var(--font-family);
      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
      text-transform: uppercase;
      color: var(--primary-black);
    }

    p {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      color: var(--secondary-grey-text);
    }
  }

  &__btns {
    display: flex;
    gap: 16px;
  }

  &__btn {
    justify-content: center !important;
    padding: 16px 12px;
  }
}