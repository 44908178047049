.city-choose {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-transform: uppercase;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    color: var(--primary-orange);
    transition: all .2s ease-in-out;
  }

  svg.icon-arrow-down {
    stroke: var(--primary-black);
  }
}