.footer {
  background-color: var(--primary-black);
  padding: var(--gap) 0;

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 440px;
    border-bottom: 1px solid #3C4248;
    padding-bottom: var(--gap);

    @media (max-width: 1200px) {
      grid-template-columns: 215px 1fr 440px;
    }

    @include md {
      grid-template-columns: 215px 1fr 1fr;
    }

    @include sm {
      display: flex;
      flex-direction: column;
    }

    &:nth-child(2) {
      border-bottom: transparent;
      padding-bottom: 0;

      @media (max-width: 1200px) {
        grid-template-columns: 215px auto auto;
      }

      @include sm {
        display: flex;
        flex-direction: column;
      }

      >.footer__privacy {
        display: none;

        @include sm {
          display: flex;
        }
      }

      >.footer__copyright {
        display: none;

        @include sm {
          display: flex;
        }
      }

      .footer__block--first {
        @include sm {
          display: none;
        }
      }

      .footer__block--second {
        flex-direction: row;

        @media (max-width: 1200px) {
          border-right: transparent;
          padding-right: 0;
        }

        @include sm {
          flex-direction: column;
          gap: 20px;
          margin-bottom: 20px;
        }
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 var(--gap);

    @media (max-width: 1200px) {
      justify-content: space-around;
    }

    &--first {
      padding-left: 0;

      @include sm {
        padding: 0;
        margin-bottom: 60px;

        .footer__privacy {
          display: none;
        }
      }
    }

    &--second {
      border-left: 1px solid #3C4248;
      border-right: 1px solid #3C4248;

      @include sm {
        padding: 0;
        margin-bottom: 40px;
        border: transparent;
      }
    }

    &--last {
      @include sm {
        padding: 0;
      }
    }

    &_wrapper {
      a {
        color: var(--primary-white);
        transition: all .2s ease-in-out;

        &:hover {
          color: var(--primary-orange);
          transition: all .2s ease-in-out;
        }

        @include lg {
          font-family: var(--font-family);
          font-weight: 700;
          font-size: 13px;
          line-height: 150%;
          text-transform: uppercase;
          color: var(--primary-white);
        }
      }

      p {
        &:nth-child(1) {
          color: var(--primary-white);

          @include lg {
            font-family: var(--font-family);
            font-weight: 700;
            font-size: 13px;
            line-height: 150%;
            text-transform: uppercase;
            color: var(--primary-white);
          }
        }

        &:nth-child(2) {
          color: var(--secondary-grey-text);

          @include lg {
            font-family: var(--font-family);
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: var(--secondary-grey-text);
          }
        }
      }
    }
  }

  &__privacy {
    display: flex;
    flex-direction: column;

    @include sm {
      padding: 24px 0;
      border-top: 1px solid #3C4248;
      border-bottom: 1px solid #3C4248;
      margin-top: 24px;
      gap: 4px;
    }

    &-link {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: var(--secondary-grey-text);
      text-decoration: none;
      transition: all .2s ease-in-out;

      &:hover {
        color: var(--primary-white);
        transition: all .2s ease-in-out;
      }

      @include lg {
        font-size: 12px;
        line-height: 120%;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__copyright {
    display: flex;
    flex-direction: column;
    gap: 2px;

    @include sm {
      padding-top: 24px;
      gap: 4px;
    }

    p {
      color: var(--secondary-grey-text);

      @include lg {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: var(--secondary-grey-text);
      }
    }
  }
}