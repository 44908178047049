.employee-card {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__photo {
    height: 300px;
    overflow: hidden;
    border-radius: var(--border-radius-5);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__position {
    color: var(--secondary-grey-text);
  }
}
