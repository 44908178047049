.photo-card {
  height: 300px;
  overflow: hidden;
  border-radius: var(--border-radius-5);

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
