.modal-filter {
  &__overlay {
    justify-content: flex-start;
    align-items: unset;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
    border-radius: 0 var(--border-radius-5) var(--border-radius-5) 0;
    padding: var(--gap);

    @include xs {
      width: 100%;
      max-width: 100%;
      border-radius: 0;
    }
  }

  &__content {
    height: 100%;
  }
}
