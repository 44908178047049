.form-submit {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--gap);
  justify-content: space-between;

  @include sm {
    flex-direction: column;
    gap: 16px;

    .btn {
      max-width: 100%;
    }
  }

  &__check {
    width: 100%;
  }

  &__label {
    position: relative;
    display: block;
    cursor: pointer;
    user-select: none;
    padding-left: 36px;

    @include lg {
      max-width: 310px;
      width: 100%;
    }

    @include sm {
      width: 100%;
    }
  }

  &__link {
    color: var(--primary-orange);
    transition: all .2s ease-in-out;

    &:hover {
      text-decoration: underline;
      transition: all .2s ease-in-out;
    }
  }

  &__checkbox+&__label::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 19px;
    height: 19px;
    background-color: var(--primary-white);
    border: 1px solid var(--secondary-grey);
    border-radius: 5px;
  }

  &__checkbox+&__label:hover:after {
    border: 1px solid var(--hover-grey);
  }

  &__checkbox:checked+&__label::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 19px;
    height: 19px;
    background-color: var(--primary-orange);
    border: 1px solid var(--primary-orange);
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 3.57143L4.42857 7L10.4286 1' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
  }

  &__checkbox:checked+&__label:hover:after {
    background-color: var(--hover-orange-hover);
    border: 1px solid var(--hover-orange-hover);
  }
}