.search-tabs {

  &__header {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 40px;
  }

  &__list {
    display: flex;

    &.hidden {
      display: none;
    }
  }

  &__services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
  }
}