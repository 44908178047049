.pricing-block {
  &__per {
    color: var(--secondary-grey-text);
  }

  &__price {
    &-accent {
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      text-transform: uppercase;
    }
  }
}
