.menu-list {
  display: flex;
  align-items: center;
  gap: var(--gap);
  padding: 0;
  margin: 0;
  list-style: none;
  font-size: 18px;
  line-height: 150%;

  @include lg {
    font-size: 16px;
  }

  @include md {
    display: none;
  }

  &.top-list {
    font-size: 13px;
    text-transform: uppercase;
    gap: 16px;
  }

  &.top-list &__link {
    gap: 4px;
    padding-bottom: 10px;

    @include lg {
      padding-top: 10px;
    }

    svg {
      padding: 0;
    }
  }

  &.bottom-list &__link {
    padding: 10px 0;
  }

  &__item {
    position: relative;
  }

  &__item:hover>&__children {
    display: block;
  }

  &__children {
    display: none;
    position: absolute;
    left: -10px;
    padding: 20px var(--gap);
    border-radius: var(--border-radius-7);
    background-color: var(--primary-white);
    box-shadow: var(--btn-shadow);
    width: 100%;
    min-width: 200px;
    z-index: 99;

    @include lg {
      top: 30px;
      left: unset;
      right: 0;
    }
  }

  &__child {
    &-link {
      display: block;
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      text-transform: uppercase;
      text-decoration: none;
      color: var(--primary-black);
      transition: all .2s ease-in-out;

      &:hover {
        transition: all .2s ease-in-out;
        color: var(--primary-orange);
      }
    }

    &:not(:first-child) &-link {
      padding-top: 8px;
    }

    &:not(:last-child) &-link {
      padding-bottom: 8px;
    }
  }

  &__link {
    display: flex;
    align-items: center;
    gap: 8px;
    text-decoration: none;
    color: var(--primary-black);
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 13px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--primary-black);
    padding-bottom: 14px;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }

    @include lg {
      padding-bottom: 0;
    }

    svg {
      fill: var(--primary-black);
      stroke: var(--primary-black);
    }
  }

  &.header-list-search {
    .menu-list__item {
      svg {
        fill: var(--primary-orange);
        stroke: var(--primary-orange);
      }
    }
  }
}