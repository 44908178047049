.filter {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--gap);
    cursor: pointer;

    svg {
      stroke: var(--secondary-grey-text);
    }

    svg.open {
      transform: rotate(180deg);
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--gap);
  }

  &__footer {
    display: none;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    @include xs {
      flex-direction: column;
      gap: 8px;
    }
  }

  &__clear {
    color: var(--secondary-grey-text);
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &-subtitle {
      text-decoration: none;
      color: var(--primary-black);
    }

    &-hidden {
      color: var(--primary-orange);
      cursor: pointer;
    }
  }

  &__content {
    display: none;
    flex-direction: column;
    gap: 16px;

    &.open {
      display: flex;
    }
  }

  &__trigger {
    &.btn {
      display: none;
      justify-content: center;
      gap: 12px;
      width: 100%;

      @include lg {
        display: flex;
      }
    }
  }
}

.modal .filter {
  &__footer {
    display: flex;
    margin-top: auto;

    &-btn {
      max-width: 100%;
      font-size: 13px;
      line-height: 120%;
    }
  }

  height: 100%;
  justify-content: space-between;
  margin-bottom: 0;
}