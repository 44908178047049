.input {
  padding: 20px;
  border: 1px solid var(--secondary-light-grey);
  border-radius: var(--border-radius-5);
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-transform: uppercase;
  color: var(--secondary-grey-text);
  background-color: var(--primary-white);

  &.file {
    display: none;
  }

  &:hover {
    border: 1px solid var(--secondary-grey);
  }

  &:focus {
    border: 1px solid var(--primary-orange);
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.file__label {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;

  &_icon {
    border-radius: 5px;
    padding: 12px;
    width: 48px;
    height: 48px;
    background: var(--primary-white);
  }

  &_text {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      margin: 0;

      &:nth-child(1) {
        font-family: var(--font-family);
        font-weight: 700;
        font-size: 13px;
        line-height: 120%;
        text-transform: uppercase;
        color: var(--primary-black);
      }

      &:nth-child(2) {
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: var(--secondary-grey-text);
      }
    }
  }
}


.file-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &:has(.file-input__file) {
    gap: 12px;
  }

  &__files {
    display: flex;
    gap: 12px;
    align-items: center;
    flex-wrap: wrap;
  }

  &__file {
    display: flex;
    gap: 12px;
    align-items: center;
    border-radius: 5px;
    padding: 12px;
    background: var(--primary-white);
    width: fit-content;

    p {
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: var(--secondary-grey-text);
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    button {
      opacity: 0.5;
      transition: all .2s ease-in-out;

      &:hover {
        opacity: 1;
        transition: all .2s ease-in-out;
      }
    }
  }

  &.error {
    .file__label_text p:nth-child(2) {
      color: var(--secondary-error);
    }
  }
}