.short-char {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: var(--gap);

    @include xs {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
    }
  }

  &__title {
    color: var(--secondary-grey-text);
  }

  &__item {
    @include xs {
      display: none;

      &:nth-child(-n + 5) {
        display: block;
      }
    }
  }

  &__show {
    color: var(--primary-orange);
    margin-top: var(--gap);
  }
}
