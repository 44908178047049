.faq {
  &__wrapper {
    display: flex;

    @include lg {
      flex-direction: column-reverse;
    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    gap: 220px;
    width: 415px;
    padding-right: var(--gap);

    @include lg {
      justify-content: space-between;
      gap: unset;
      padding-top: var(--gap);
      width: 100%;
      flex-direction: row;
    }

    @include sm {
      flex-direction: column;
      gap: 40px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include lg {
      width: 391px;
      min-width: 391px;
    }

    @include sm {
      width: 100%;
      min-width: 100%;
    }
  }

  &__heading {
    @include md {
      font-size: 40px;
      line-height: 120%;
    }
  }

  &__subtitle {
    width: max-content;
    padding-bottom: 8px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }
  }

  &__right {
    padding-left: var(--gap);
    flex: 1;

    @include lg {
      padding-left: 0;
      padding-bottom: var(--gap);
      border-bottom: 1px solid var(--secondary-light-grey);
    }
  }
}