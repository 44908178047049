.contact-map {
  display: flex;
  flex-direction: column;
  position: relative;

  .contact-map__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include sm {
      height: 320px;
      position: relative;
      inset: 0;
    }

    @include xs {
      height: 180px;
    }
  }

  &__wrapper {
    position: relative;
    width: fit-content;
    padding: 60px 0;

    @include md {
      width: 100%;
    }

    @include sm {
      padding: 40px 0;
    }
  }
}
