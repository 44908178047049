.contact-choose {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 400;
  font-size: 13px;
  line-height: 150%;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 14px;

  @include lg {
    padding-bottom: 0;
  }

  svg:not(.contact-choose__wrapper svg) {
    cursor: pointer;
    fill: var(--primary-black);
    stroke: var(--primary-black);
  }

  &:hover>&__wrapper {
    display: flex;
  }

  &__wrapper {
    position: absolute;
    top: 34px;
    left: -24px;
    display: none;
    flex-direction: column;
    gap: 16px;
    width: max-content;
    background-color: var(--primary-white);
    padding: 20px var(--gap);
    border-radius: var(--border-radius-5);
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 13;

    @include lg {
      left: unset;
      right: 0;
    }
  }
}