.services-cat {
  &__inner {
    display: flex;
    justify-content: space-between;

    a {
      transition: all .2s ease-in-out;

      &:hover {
        color: var(--primary-orange);
        transition: all .2s ease-in-out;
      }
    }

    .accordion__wrapper {
      display: flex;
      flex-direction: column;
      max-width: 526px;
      width: 100%;

      .accordion {

        border-bottom: 1px solid var(--secondary-light-grey);

        &-trigger {
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          padding: 16px 0;

          svg {
            transform: rotate(180deg);
            transition: all .2s ease-in-out;
          }
        }

        &-content {
          display: flex;
          flex-direction: column;
          padding-left: 32px;
          border-top: 1px solid var(--secondary-light-grey);

          @include xs {
            padding-left: 0;
          }

          a {
            padding: 12px 0px;

            @include xs {
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  &__item {
    padding: 16px 0;
    border-bottom: 1px solid var(--secondary-light-grey);

    @include xs {
      a {
        font-size: 16px;
      }
    }
  }

  &__img {
    @include sm {
      display: none;
    }
  }
}