.num-list {
  border-radius: 5px;
  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  background-color: var(--primary-white);
  margin: 0;

  &__item {
    list-style-type: decimal;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: var(--primary-orange);
  }

  &__subtitle {
    color: var(--primary-black);
    text-transform: uppercase;
  }

  &__desc {
    color: var(--secondary-grey-text);
  }
}
