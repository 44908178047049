.cookie-policy {
  display: none;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-white);
  position: fixed;
  bottom: 20px;
  left: 20px;
  padding: 20px;
  width: 100%;
  max-width: 573px;
  border-radius: var(--border-radius-5);
  z-index: 999;
  box-shadow: var(--btn-shadow);

  @include sm {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }

  &.open {
    display: flex;
  }

  @include sm {
    flex-direction: column;
    gap: 12px;
    padding: 14px 15px;

    & svg {
      display: none;
    }
  }

  &__text {
    @include xs {
      font-size: 14px;
    }
  }

  &__btn {
    padding: 16px 12px;

    @include sm {
      width: 100% !important;
      justify-content: center;
    }
  }
}