.template-grid {
  &__wrapper {
    display: grid;

    @include lg {
      grid-gap: 60px;
    }
  }

  &--right &__wrapper {
    grid-template-columns: 1fr 281px;

    @include lg {
      grid-template-columns: 1fr;
    }
  }

  &--right &__wrapper &__aside {
    padding-left: var(--gap);

    @include lg {
      padding-left: 0;
    }
  }

  &--right &__wrapper &__content {
    padding-right: var(--gap);
    border-right: 1px solid var(--secondary-light-grey);

    @include lg {
      padding-right: 0;
      border-right: none;
    }
  }

  &--left &__wrapper {
    grid-template-columns: 330px 1fr;

    @include lg {
      grid-template-columns: 1fr;
    }
  }

  &--left &__wrapper &__aside {
    padding-right: var(--gap);

    @include lg {
      padding-right: 0;
    }
  }

  &--left &__wrapper &__content {
    padding-left: var(--gap);
    border-left: 1px solid var(--secondary-light-grey);

    @include lg {
      padding-left: 0;
      border-left: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 80px;

    &-subtitle {
      margin-bottom: 12px;
    }

    &-block {
      position: relative;

      &:not(:last-child)::after {
        content: "";
        position: absolute;
        bottom: -40px;
        width: 100%;
        height: 1px;
        background-color: var(--secondary-light-grey);
      }
    }
  }

  &__aside {
    @include lg {
      display: none;
    }
  }

  &__header {
    padding-bottom: var(--gap);
    margin-bottom: var(--gap);
    border-bottom: 1px solid var(--secondary-light-grey);
  }
}
