.btn {
  display: flex;
  align-items: center;
  gap: 16px;
  width: max-content;
  max-height: 48px;
  padding: 12px;
  box-sizing: border-box;
  border-radius: var(--border-radius-5);
  background-color: var(--primary-blue);
  color: var(--primary-white);
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    fill: var(--primary-white);
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }

  &--blue {
    justify-content: space-between;

    svg {
      fill: var(--primary-white);
      width: 24px;
      height: 24px;
    }

    &:hover {
      background-color: var(--hover-blue-hover);
    }

    @include xs {
      max-width: 100%;
    }
  }

  &--grey {
    background-color: var(--secondary-grey);
    color: var(--primary-black);

    &:hover {
      background-color: var(--secondary-light-grey);
    }

    &:disabled {
      opacity: 0.4;
    }

    svg {
      fill: var(--primary-black);
    }
  }

  &--grey-bg {
    background-color: var(--secondary-bg);
    color: var(--primary-black);

    &:hover {
      background-color: var(--secondary-light-grey);
    }

    &:disabled {
      opacity: 0.4;
    }

    svg {
      fill: var(--primary-black);
    }
  }

  &--white {
    background-color: var(--primary-white);
    color: var(--primary-black);

    &:hover:not(:disabled) {
      box-shadow: var(--btn-shadow);
    }

    svg {
      fill: var(--primary-orange);
    }
  }

  &--orange {
    background-color: var(--primary-orange);
    color: var(--primary-white);

    &:hover:not(:disabled) {
      background-color: var(--primary-orange);
    }

    svg {
      fill: var(--primary-white);
    }
  }

  &--200 {
    width: 100%;
    max-width: 200px;
  }

  &--submit {
    border: none;
  }
}
