.dap-list {
  display: flex;
  flex-direction: column;
  gap: var(--gap);

  @include md {
    gap: 16px;
  }

  &__item {
    border-radius: 10px;
    padding: 24px;
    background-color: var(--primary-white);
    display: flex;
    flex-direction: column;
    gap: 20px;

    svg {
      @include lg {
        width: 40px;
        height: 40px;
      }
    }

    @include md {
      gap: 4px;
    }

    @include sm {
      gap: 8px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    text-transform: uppercase;
    color: var(--secondary-grey-text);

    @include lg {
      font-size: 13px;
    }

    @include md {
      font-size: 12px;
    }
  }
}
