.page-header {
  padding-bottom: 60px;

  @include xs {
    padding-bottom: 40px;
  }

  &__wrapper {
    padding-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
}