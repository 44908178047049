.branches {
  position: relative;

  &__wrapper {
    @include lg {
      display: flex;
      flex-direction: column;
      gap: var(--gap);
    }
  }

  &__left {
    width: 100%;
    max-width: 416px;

    @include lg {
      max-width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, auto);
      grid-column-gap: 24px;
      grid-row-gap: 24px;

      .branches__block--first {
        grid-area: 1 / 1 / 2 / 2;
      }

      .branches__block--second {
        grid-area: 1 / 2 / 2 / 3;
      }

      .branches__map {
        grid-area: 2 / 1 / 3 / 3;
      }

      .city-tags {
        max-width: 416px;
      }
    }

    @include sm {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .city-tags {
        max-width: 100%;
      }
    }
  }

  &__map {
    position: absolute;
    bottom: 0;
    left: 450px;

    @media (max-width: 1680px) {
      .map-rf svg:not(.map-rf__window svg) {
        width: 900px;

        @include lg {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    @include lg {
      position: relative;
      left: unset;
    }

    @include sm {
      order: 2;
    }
  }

  &__title {
    margin-bottom: var(--gap);

    @include md {
      font-size: 40px;
      line-height: 140%;
    }
  }

  &__text {
    margin-top: 70px;
    padding-bottom: 40px;
    border-bottom: 1px solid var(--secondary-light-grey);

    @include lg {
      margin-top: 0;
    }
  }

  &__more {
    width: max-content;
    padding-top: 20px;
    display: block;
    transition: all .2s ease-in-out;

    &:hover {
      color: var(--primary-orange);
      transition: all .2s ease-in-out;
    }

    @include lg {
      margin-bottom: auto;
    }
  }

  &__block {
    &--first {
      @include sm {
        order: 1;
      }
    }

    &--second {
      @include sm {
        order: 3;
      }
    }
  }
}