.search-input {
  position: relative;
  z-index: 9;
  width: 100%;
  display: none;

  &.active {
    display: block;

    @include xs {
      position: fixed;
      top: 16px;
      left: 11px;
      width: 93%;
    }

    .search-input__inner {
      border: 1px solid var(--primary-orange);
    }
  }

  &__inner {
    border-radius: 5px;
    padding: 0px 0px 0px 12px;
    display: flex;
    align-items: center;
    background: var(--primary-white);
    position: relative;
    width: 100%;
    z-index: 8;


    input {
      width: 100%;
      margin: 0 10px;
      height: 100%;
      padding: 12px 0;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
      color: var(--primary-black);

      &::placeholder {
        vertical-align: middle;
        font-family: var(--font-family);
        font-weight: 400;
        font-size: 13px;
        line-height: 150%;
        text-transform: uppercase;
        color: var(--secondary-grey);
      }
    }
  }

  &__clear {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 12px;
    line-height: 120%;
    color: var(--secondary-grey-text);
    margin-right: 4px;
    display: none;

    &.active {
      display: block;
    }
  }

  &__close {
    border-radius: 5px;
    min-width: 48px;
    height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__submit {
    svg {
      min-width: 24px;
    }
  }

  &__requests {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background: var(--primary-white);
    padding-top: 60px;
    border-radius: 5px;

    &.active {
      display: block;
    }

    p {
      padding: 8px 16px;
      border-bottom: 1px solid var(--secondary-light-grey);
      padding-bottom: 8px;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 12px;
      line-height: 120%;
      color: var(--secondary-grey-text);
    }

    &_list {
      display: flex;
      flex-direction: column;
    }

    &_item {
      display: flex;
      gap: 12px;
      align-items: center;
      font-family: var(--font-family);
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: var(--primary-black);
      border-radius: 5px;
      padding: 8px 16px;
      transition: all .2s ease-in-out;

      img {
        border-radius: 5px;
        width: 32px;
        height: 32px;
      }

      @media (hover:hover) {
        &:hover {
          background: var(--secondary-bg);
          transition: all .2s ease-in-out;
        }
      }
    }

    &_bottom {
      border-radius: 5px;
      padding: 8px;

    }

    &_btn {
      width: 100% !important;
    }
  }
}

.search-btn-open {
  &.hidden {
    display: none;
  }
}

.search-input-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: var(--primary-black);
  opacity: 0.6;
  z-index: 1;

  @include xs {
    &.active {
      display: block;
    }
  }
}