.container {
  width: 100%;
  max-width: 1296px;
  margin: 0 auto;

  @include lg {
    padding: 0 44px;
  }

  @include md {
    padding: 0 36px;
  }

  @include sm {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include xs {
    padding: 0 12px;
  }
}