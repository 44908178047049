.txt-img {
  &__title {
    margin-bottom: 0 !important;
  }

  &__content {
    padding-top: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    max-width: 636px;

    @include lg {
      padding-top: 0;
      max-width: 100%;
    }
  }

  &__info {
    h3 {
      font-weight: 700;
      font-size: 20px;
      line-height: 150%;
      text-transform: uppercase;
      color: var(--primary-black);

      &:not(:first-child) {
        margin-top: 40px;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: var(--secondary-grey-text);

      &:not(:first-child) {
        margin-top: 30px;
      }
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: var(--gap);

    @include xs {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__btn {
    &-text {
      width: 112px;

      @include xs {
        width: 100%;
      }
    }

    @include xs {
      max-width: 100% !important;
    }
  }

  &__image {
    position: absolute;
    bottom: 0;
    right: -110%;

    @include lg {
      display: none;
    }
  }
}
