.header-bar {
  display: flex;
  gap: 16px;

  .burger {
    display: none;
  }

  @include lg {
    .fast-buy {
      display: none;
    }
  }

  @include md {
    gap: 12px;

    .burger {
      display: flex;
    }
  }
}
