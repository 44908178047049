@font-face {
  font-family: "Arimo";
  src: url("../assets/fonts/Arimo-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arimo";
  src: url("../assets/fonts/Arimo-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}