.listing-vacancy {
  .listing-vacancy__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
    padding-bottom: 24px;

    @include sm {
      grid-template-columns: 1fr 1fr;
    }

    @include xs {
      grid-template-columns: 1fr;
    }
  }

  p {
    margin: 0;
  }
}